import React, {useContext, useEffect, useRef, useState} from 'react';
import UserSearch from '../standard/UserSearch';
import {TextField} from '@material-ui/core';
import * as DateUtils from '../../utilities/DateUtils';
import {Link, Redirect} from 'react-router-dom';
import Notification from '../standard/Notification';
import Textbox from '../standard/Textbox';
import Toggle from '../standard/Toggle';
import CheckmarkSVG from '../../images/CheckmarkSVG';
import ErrorSVG from '../../images/ErrorSVG';
import InformationSVG from '../../images/InformationSVG';
import UserContext from '../../context/UserContext';
import AxiosRequest from '../../utilities/AxiosRequest';
import * as Constants from '../../utilities/Constants';
import * as ValidateUtils from '../../utilities/ValidateUtils';
import {createParkingCalendarInvite} from '../../utilities/EmailCalendarUtils';

//Marathon Modal
import {Modal} from 'react-bootstrap';

function ReserveParking(props) {
    const [cancel, setCancel] = useState(false);
    const currentUser = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState('');
    const existingReservation = useRef(props.location.state?.reservation !== undefined ? props.location.state.reservation : null);
    const [form, setForm] = useState({
        contactPhone: '',
        vehiclePlate: '',
        vehicleState: '',
        vehicleMake: '',
        vehicleModel: '',
        vehicleColor: ''
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isVehicleUnknown, setIsVehicleUnknown] = useState(false);
    const [parkingUser, setParkingUser] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [successMessage, setSuccessMessage] = useState('Boston parking reservation successfully created');
    const [maxDate, setMaxDate] = useState(new Date());
    const [isOvernight, setIsOvernight] = useState(false);

    // Marathon modal
    const [showModal, setShowModal] = useState(true);

    useEffect(() => {
        const getReservationLimit = async () => {
            try {
                const response = await AxiosRequest.get('/api/v1/offices?isParking=true');
                if (response.status === 200) {
                    if (response.data[0].reservationLimit > 0) {
                        setMaxDate(maxDate => new Date(maxDate.setDate((maxDate.getDate() + response.data[0].reservationLimit))));
                    } else {
                        setMaxDate(new Date('9999-12-31'));
                    }
                } else {
                    setIsError(true);
                    setErrorMessage('An error occurred retrieving reservation limit');
                }
            } catch (err) {
                setIsError(true);
                setErrorMessage(err.response.data.message || err.response.data.error);
            }
        }
        getReservationLimit();
    }, []);

    useEffect(() => {
        const getExistingReservation = async (reservation) => {
            try {
                const response = await AxiosRequest.get(`/api/v1/reservations/${reservation.id}`);
                if (response.status === 200) {
                    existingReservation.current = response.data;
                    setIsVehicleUnknown(!existingReservation.current?.vehiclePlate || !existingReservation.current?.vehicleState);
                    setSelectedDates([DateUtils.parseDate(response.data.reservationDateGroup[0]), DateUtils.parseDate(response.data.reservationDateGroup[1])]);
                    setForm({
                        contactPhone: ValidateUtils.formatPhone(existingReservation.current.contactPhone),
                        vehiclePlate: existingReservation.current.vehiclePlate,
                        vehicleState: existingReservation.current.vehicleState,
                        vehicleMake: existingReservation.current.vehicleMake,
                        vehicleModel: existingReservation.current.vehicleModel,
                        vehicleColor: existingReservation.current.vehicleColor
                    });
                    setIsOvernight(existingReservation.current.isOvernight);
                } else {
                    setIsError(true);
                    setErrorMessage('An error occurred retrieving reservation to edit');
                }
            } catch (err) {
                setIsError(true);
                setErrorMessage(err.response.data.message || err.response.data.error);
            }
        }
        if (existingReservation.current) {
            setIsEditing(true);
            getExistingReservation(existingReservation.current);
        }
    }, [props]);

    const getParkingUser = async (employeeId) => {
        try {
            const response = await AxiosRequest.get(`/api/v1/users?employeeId=${employeeId}`);
            if (response.status === 200) {
                setParkingUser(response.data);
            } else {
                setIsError(true);
                setErrorMessage('An error occurred retrieving parking eligibility for user');
            }
        } catch (error) {
            setIsError(true);
            setErrorMessage('An error occurred retrieving parking eligibility for user');
        }
    };

    const getParkingUserWithAvailability = async (parkingUserId, startDate, endDate) => {
        try {
            const response = await AxiosRequest.get(`/api/v1/users/${parkingUserId}?startDate=${startDate}&endDate=${endDate}`);
            if (response.status === 200) {
                setParkingUser(response.data);
            } else {
                setIsError(true);
                setErrorMessage('An error occurred retrieving parking eligibility for user');
            }
        } catch (error) {
            setIsError(true);
            setErrorMessage('An error occurred retrieving parking eligibility for user');
        }
    };

    useEffect(() => {
        getParkingUser(currentUser.employeeId);
    }, [currentUser.employeeId]);

    const parkingUserId = parkingUser?.id;
    useEffect(() => {
        if (parkingUserId && selectedDates[0] && selectedDates[1]) {
            getParkingUserWithAvailability(parkingUserId, DateUtils.getYMDDate(selectedDates[0]), DateUtils.getYMDDate(selectedDates[1]));
        }
    }, [parkingUserId, selectedDates]);

    const updateFormValue = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        });
    };

    const clearFormField = (name) => {
        setForm({
            ...form,
            [name]: ''
        });
    };

    // Marathon modal
    const dismissModal = () => {
        setShowModal(false);
    };

    const reserveParking = async (event) => {
        event.preventDefault();
        resetNotifications();
        try {
            const newParkingReservation = [{
                employeeId: parkingUser.employeeId,
                reservationDateGroup: [DateUtils.getYMDDate(selectedDates[0]), DateUtils.getYMDDate(selectedDates[1])],
                reserverId: currentUser.id,
                officeId: 1,
                reservationType: Constants.RESERVATION_TYPE.PARKING,
                vehiclePlate: isVehicleUnknown ? '' : form.vehiclePlate.toUpperCase(),
                vehicleState: isVehicleUnknown ? '' : form.vehicleState.toUpperCase(),
                vehicleMake: isVehicleUnknown ? '' : form.vehicleMake.toUpperCase(),
                vehicleModel: isVehicleUnknown ? '' : form.vehicleModel.toUpperCase(),
                vehicleColor: isVehicleUnknown ? '' : form.vehicleColor.toUpperCase(),
                contactPhone: form.contactPhone.replace(/\D/g, ''),
                isOvernight: isOvernight
            }];
            if (!existingReservation.current) {
                const response = await AxiosRequest.post('/api/v1/reservations', newParkingReservation);
                if (response.status === 201) {
                    existingReservation.current = {...newParkingReservation[0], id: response.data[0][0].id};
                    setIsSuccess(true);
                } else {
                    setIsError(true);
                    setErrorMessage('An error occurred reserving a parking space');
                }
            } else {
                const updatedParkingReservation = {
                    ...newParkingReservation[0],
                    id: existingReservation.current.id,
                    employeeId: existingReservation.current.employeeId
                };
                const response = await AxiosRequest.put(`/api/v1/reservations/${existingReservation.current.id}`, updatedParkingReservation);
                if (response.status === 200) {
                    setIsSuccess(true);
                    existingReservation.current = updatedParkingReservation;
                    setSuccessMessage('Boston parking reservation successfully updated');
                } else {
                    setIsError(true);
                    setErrorMessage('An error occurred updating parking reservation');
                }
            }
        } catch (error) {
            setIsError(true);
            setErrorMessage(error.response.data.message || error.response.data.error);
        }
    };

    const resetUser = () => {
        setParkingUser({isParkingEligible: null, isParkingAvailable: null});
        setSelectedDates([]);
    };

    const resetNotifications = () => {
        setIsSuccess(false);
        setIsError(false);
        setErrorMessage('');
    };

    return (
        cancel ?
            <Redirect to={'/'}/>
            :
            <>

                {/*Marathon Modal*/}
                <div>
                    <Modal show={showModal} onHide={() => setShowModal(false)} size='sm' backdrop='static'
                           animation={false}
                           className={'marathon-modal'} centered>
                        <Modal.Body>
                            <div>
                                <p>
                                    Monday, April 21st: Area streets will be barricaded and the parking garage will be
                                    closed from April 20 at 8 p.m. to April 21 at 11 p.m. for the Boston Marathon.
                                </p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='justify-content-center'>
                            <button className='btn btn--primary' active='true' onClick={dismissModal}>OK</button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div id={'reserve-parking-form'} className={'mx-auto'}>
                    <Notification id={'parking-success-notification'} notificationType={'success'} dismissable={true}
                                  dismissText={'Click to close'} active={isSuccess}
                                  handleDismiss={resetNotifications}>{successMessage}<br/><Link
                        to={'/reservation-dashboard'}>View
                        Upcoming Reservations</Link><br/><span className={'link'} style={{fontSize: '16px'}}
                                                               onClick={existingReservation.current !== null ? () => createParkingCalendarInvite(existingReservation.current, 'Boston, MA') : null}>Download Calendar Invite</span></Notification>
                    <Notification id={'parking-error-notification'} notificationType={'error'} dismissable={true}
                                  dismissText={'Click to close'} active={isError}
                                  handleDismiss={resetNotifications}>{errorMessage}</Notification>
                    <section>
                        <h3>{existingReservation.current ? 'Edit' : 'New'} Boston Parking Reservation</h3>
                        {parkingUser ?
                            <form onSubmit={reserveParking}>
                                <div className={'row mb-2'}>
                                    <div className={'col'}><span className={'lead'}>Employee Information</span></div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col'}>
                                        <UserSearch
                                            placeholderText={'Enter employee\'s last name or ID...'}
                                            setSearchResults={(employees) => employees.length ? getParkingUser(employees[0].employeeId) : resetUser()}
                                            defaultTextDisplay={existingReservation.current?.employeeName ? existingReservation.current.employeeName : parkingUser.name}
                                            blurMethod={(textboxValue) => {
                                                if (!textboxValue) resetUser()
                                            }}
                                            isDisabled={existingReservation.current}
                                        />
                                    </div>
                                </div>
                                <div className={'row mb-3'}>
                                    <div className={'col'}>
                                        {parkingUser.isParkingEligible ?
                                            <div>
                                                <CheckmarkSVG width={18} height={18}
                                                              title={'User is eligible for Boston parking'}/>&nbsp;
                                                This user <span
                                                style={{color: 'var(--success-text)'}}>is eligible</span> for Boston
                                                parking
                                            </div>
                                            :
                                            <div>
                                                <ErrorSVG width={18} height={18}
                                                          title={'User is not eligible for Boston parking'}/>&nbsp;
                                                This user <span
                                                style={{color: 'var(--error-text)'}}>is not eligible</span> for Boston
                                                parking
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col'}>
                                        <label htmlFor={'start-date'} className={'mr-1'}>Dates<span
                                            style={{color: 'var(--error-text)'}}> *</span></label>
                                        <TextField type={'date'} id={'start-date'}
                                                   disabled={!parkingUser.isParkingEligible || (existingReservation.current && isEditing)}
                                                   value={selectedDates[0] ? DateUtils.getYMDDate(selectedDates[0]) : ''}
                                                   InputProps={{
                                                       className: 'date-field',
                                                       disableUnderline: true,
                                                       required: true
                                                   }}
                                                   inputProps={{
                                                       min: DateUtils.getYMDDate(new Date()),
                                                       max: DateUtils.getYMDDate(maxDate)
                                                   }}
                                                   onChange={(event) => {
                                                       setIsEditing(false);
                                                       setSelectedDates(DateUtils.changeStartDate(event.target.value, selectedDates[1]));
                                                       existingReservation.current = null;
                                                   }}
                                        />
                                        <label htmlFor={'end-date'} className={'text-center m-1'}>to</label>
                                        <TextField type={'date'} id={'end-date'}
                                                   disabled={!parkingUser.isParkingEligible || (existingReservation.current && isEditing)}
                                                   value={selectedDates[1] ? DateUtils.getYMDDate(selectedDates[1]) : ''}
                                                   InputProps={{
                                                       className: 'date-field',
                                                       disableUnderline: true,
                                                       required: true
                                                   }}
                                                   inputProps={{
                                                       min: DateUtils.getYMDDate(new Date()),
                                                       max: DateUtils.getYMDDate(maxDate)
                                                   }}
                                                   onChange={(event) => {
                                                       setIsEditing(false);
                                                       setSelectedDates(DateUtils.changeEndDate(event.target.value, selectedDates[0]));
                                                       existingReservation.current = null;
                                                   }}
                                        />
                                    </div>
                                </div>
                                <div className={'row mb-2'}>
                                    <div className={'col'}>
                                        {parkingUser.isParkingEligible ?
                                            selectedDates[0] && selectedDates[1] ?
                                                <div>
                                                    {isEditing || parkingUser.isParkingAvailable ?
                                                        <div>
                                                            <CheckmarkSVG width={18} height={18}
                                                                          title={'There is a Boston parking spot available'}/>&nbsp;
                                                            Spaces in the Boston garage <span
                                                            style={{color: 'var(--success-text)'}}>are available</span>
                                                        </div>
                                                        :
                                                        <div>
                                                            <ErrorSVG width={18} height={18}
                                                                      title={'There is not a Boston parking spot available'}/>&nbsp;
                                                            Spaces in the Boston garage <span
                                                            style={{color: 'var(--error-text)'}}>are not available</span>
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <div>
                                                    <InformationSVG width={18} height={18}
                                                                    title={'Please select dates'}/>&nbsp;
                                                    Please select
                                                    dates {parkingUser.isParkingAvailable ? 'to make a reservation' : 'to determine space availability'}
                                                </div>
                                            :
                                            <div>
                                                <ErrorSVG width={18} height={18}
                                                          title={'Boston parking is for eligible users only'}/>&nbsp;
                                                Only eligible users can reserve a space in the Boston garage
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={'row mb-3'}>
                                    <div className={'col'}>
                                        <Toggle label={'Include overnight parking for selected dates'}
                                                defaultChecked={isOvernight} toggleValue={setIsOvernight}
                                                isDisabled={!parkingUser.isParkingEligible || !parkingUser.isParkingAvailable}/>
                                    </div>
                                </div>
                                <div className={'row mb-2'}>
                                    <div className={'col'}>
                                        <Textbox id={'contactPhone'} textboxType={Constants.INPUT_TYPES.PHONE}
                                                 initialValue={isEditing && existingReservation.current?.contactPhone ? ValidateUtils.formatPhone(existingReservation.current.contactPhone) : ''}
                                                 placeholderText={'Contact Phone Number'} isRequired={true}
                                                 requiredMessage={'Please enter a valid contact phone number.'}
                                                 isDisabled={!existingReservation.current && (!parkingUser.isParkingEligible || !parkingUser.isParkingAvailable)}
                                                 changeMethod={(event) => {
                                                     updateFormValue(event);
                                                     event.target.value = ValidateUtils.formatPhone(event.target.value);
                                                 }} clearMethod={() => clearFormField('contactPhone')} maxLength={19}
                                                 isReadOnly={false}/>
                                    </div>
                                </div>
                                <div className={'row mb-2'}>
                                    <div className={'col'}><span className={'lead'}>Vehicle Information</span></div>
                                </div>
                                <div className={'row mb-2'}>
                                    <div className={'col'}>
                                        <Toggle label={'I don\'t know my vehicle information'}
                                                defaultChecked={isVehicleUnknown} toggleValue={setIsVehicleUnknown}
                                                isDisabled={existingReservation.current?.vehiclePlate || existingReservation.current?.vehicleState || !parkingUser.isParkingEligible || !parkingUser.isParkingAvailable}/>
                                    </div>
                                </div>
                                <div className={'row mb-2'}>
                                    <div className={'col'}>
                                        <Textbox id={'vehiclePlate'} textboxType={Constants.INPUT_TYPES.TEXT}
                                                 initialValue={existingReservation.current?.vehiclePlate}
                                                 allowAll={true}
                                                 placeholderText={'License Plate Number'} isRequired={true}
                                                 requiredMessage={'Please enter a valid license plate number.'}
                                                 isDisabled={(!isEditing && (!parkingUser.isParkingEligible || !parkingUser.isParkingAvailable || isVehicleUnknown)) || (isEditing && isVehicleUnknown)}
                                                 changeMethod={updateFormValue}
                                                 clearMethod={() => clearFormField('vehiclePlate')} maxLength={30}
                                                 isReadOnly={false}/>
                                    </div>
                                    <div className={'col'}>
                                        <Textbox id={'vehicleState'} textboxType={Constants.INPUT_TYPES.TEXT}
                                                 initialValue={existingReservation.current?.vehicleState}
                                                 placeholderText={'License Plate State'} isRequired={true}
                                                 requiredMessage={'Please enter a valid license plate state.'}
                                                 isDisabled={(!isEditing && (!parkingUser.isParkingEligible || !parkingUser.isParkingAvailable || isVehicleUnknown)) || (isEditing && isVehicleUnknown)}
                                                 changeMethod={updateFormValue}
                                                 clearMethod={() => clearFormField('vehicleState')} maxLength={2}
                                                 isReadOnly={false}/>
                                    </div>
                                </div>
                                <div className={'row mb-2'}>
                                    <div className={'col'}>
                                        <Textbox id={'vehicleMake'} placeholderText={'Vehicle Make'}
                                                 initialValue={existingReservation.current?.vehicleMake}
                                                 isDisabled={(!isEditing && (!parkingUser.isParkingEligible || !parkingUser.isParkingAvailable || isVehicleUnknown)) || (isEditing && isVehicleUnknown)}
                                                 changeMethod={updateFormValue}
                                                 clearMethod={() => clearFormField('vehicleMake')} maxLength={30}
                                                 isReadOnly={false}/>
                                    </div>
                                    <div className={'col'}>
                                        <Textbox id={'vehicleModel'} placeholderText={'Vehicle Model'}
                                                 initialValue={existingReservation.current?.vehicleModel}
                                                 isDisabled={(!isEditing && (!parkingUser.isParkingEligible || !parkingUser.isParkingAvailable || isVehicleUnknown)) || (isEditing && isVehicleUnknown)}
                                                 changeMethod={updateFormValue}
                                                 clearMethod={() => clearFormField('vehicleModel')} maxLength={30}
                                                 isReadOnly={false}/>
                                    </div>
                                </div>
                                <div className={'row mb-2'}>
                                    <div className={'col'}>
                                        <Textbox id={'vehicleColor'} placeholderText={'Vehicle Color'}
                                                 initialValue={existingReservation.current?.vehicleColor}
                                                 isDisabled={(!isEditing && (!parkingUser.isParkingEligible || !parkingUser.isParkingAvailable || isVehicleUnknown)) || (isEditing && isVehicleUnknown)}
                                                 changeMethod={updateFormValue}
                                                 clearMethod={() => clearFormField('vehicleColor')} maxLength={30}
                                                 isReadOnly={false}/>
                                    </div>
                                    <div className={'col'}></div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col'}>
                                        <input type={'submit'} className={'btn btn--primary mr-2'}
                                               value={isEditing ? 'Save' : 'Reserve'}
                                               disabled={!parkingUser.isParkingEligible || !parkingUser.isParkingAvailable}/>
                                        <input type={'button'} className={'btn btn--secondary'} value={'Cancel'}
                                               onClick={() => setCancel(true)}/>
                                    </div>
                                </div>
                            </form>
                            :
                            null
                        }
                    </section>
                </div>
            </>
    );
}

export default ReserveParking;